import { combineReducers } from "redux";
import { messageReducer } from "@agricircle/shared/redux";
import { farmsReducer } from "@agricircle/shared/farms/redux";
import pasture from "./pasture";

export const reducer = combineReducers({
    messages: messageReducer,
    farms: farmsReducer,
    pasture
});

export const RootState = reducer;

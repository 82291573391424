import { Stack, Button, Box, Typography } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@agricircle/shared";
import { apiUrl } from "@agricircle/shared/hooks";
import { LanguageSelector } from "@agricircle/shared/widgets";
import "./Product.css";
import logo from "@agricircle/shared/assets/agricircle_logo.svg";

const YOUTUBE_VIDEOS = [
];

export default () => {
    const { t, i18n } = useTranslation();
    const { profile } = useAuthContext();
    const lang = i18n.language == "de" ? "de" : "en";
    const unauthorized = profile.id && !profile.organization?.roles?.includes("pasture");

    function handleLogin() {
        window.location.href = `${apiUrl}/auth/login?after_login=https://${window.location.host}/map`;
    }

    function handleVideo(video) {
        window.open(`https://www.youtube.com/embed/${video[lang]}?autoplay=1`, "_blank");
    }

    return (<Box className="home" sx={{ width: "100%", backgroundImage: "url(/pasture.webp)", backgroundSize: "cover" }}>
        <Box sx={{ backdropFilter: "brightness(60%) blur(3px)", padding: "10px" }}>
            <Stack sx={{ maxWidth: 1050, marginLeft: "auto", marginRight: "auto" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <img src={logo} style={{ width: 60 }} />
                        <LanguageSelector small />
                    </Stack>
                    <Stack alignItems="end">
                        <h1>{t("title-pasture")}</h1>
                        <Stack direction="row" alignItems="center">
                            {profile.id
                                ? unauthorized
                                    ? <Typography color="error">{t("shared:unauthorized")}</Typography>
                                    : <Button
                                        href="/map"
                                        variant="outlined"
                                        style={{ color: "white", borderColor: "white" }}
                                    >{t("shared:btn-my-account")}
                                    </Button>
                                : <Stack direction="row" spacing={2}>
                                    <Button
                                        color="success"
                                        variant="outlined"
                                        onClick={handleLogin}
                                        style={{ color: "white", borderColor: "white" }}
                                        data-cy="nav-login"
                                    >{t("shared:btn-login")}
                                    </Button>
                                </Stack>}
                        </Stack>
                    </Stack>
                </Stack>
                <h2>{t("title-pasture-tagline")}</h2>
                <div className="tutorial">
                    {YOUTUBE_VIDEOS.map(video => (<a onClick={() => handleVideo(video)} key={video.en}>
                        <img src={`https://img.youtube.com/vi/${video[lang]}/mqdefault.jpg`} />
                        <PlayCircleFilledWhiteIcon color="red" sx={{
                            width: "50px",
                            height: "50px",
                            opacity: 0.7,
                            color: "gray",
                            position: "absolute",
                            top: "55px",
                            right: "22px",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1
                        }} />
                    </a>))}
                </div>
                <h3>{t("title-pasture-how-it-works")}</h3>
                <div dangerouslySetInnerHTML={{ __html: t("text-pasture-how-it-works") }} />
                <h3>{t("title-pasture-value")}</h3>
                <div dangerouslySetInnerHTML={{ __html: t("text-pasture-value") }} />
                <h3>{t("title-pasture-next")}</h3>
                <div dangerouslySetInnerHTML={{ __html: t("text-pasture-next") }} />
            </Stack>
        </Box>
    </Box>);
};

import { produce } from "immer";

const initialState = {
    mode: "work"
};

const reducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_MODE":
            draft.mode = action.payload.mode;
            break;
    }
});

export default reducer;

export const setMode = (mode) => ({ type: "SET_MODE", payload: { mode } });

